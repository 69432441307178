import React from "react"
import Window from "../Window"
import WindowCircleButtons from "../Window/WindowCircleButtons"
import Button from "../Button"
import { Control, Form, actions, Errors } from 'react-redux-form'
import './ContactForm.scss'
import { connect } from "react-redux"
import Modal from "../Modal"

const ContactTextInput = (props) => <input className="contact-us__form__input" {...props} />
const ContactTextAreaInput = (props) => <textarea className="contact-us__form__textarea" {...props} />
const ContactFormErrors = (props) => <div className="contact-us__form__errors">{props.children}</div>
const ContactFormError = (props) => <div className="contact-us__form__error">{props.children}</div>

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class ContactForm extends React.Component {
  state = {
    modalShow: false
  }

  triggerModal = (show) => {
    this.setState(prev => ({
      ...prev,
      modalShow: show
    }))
  }

  postContactUs = (values) => {
    const dispatch = this.props.dispatch

    dispatch(actions.setPending('contactForm', true))
    fetch('https://dev.laurus.agency/laurusagency/contactUs', {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then(res => res.json())
      .then(res => {
          if (!res['success'])
            throw res
          dispatch(actions.reset('contactForm'))
          this.triggerModal(true)
        }
      )
      .catch(err => {
        console.error(err)
        err['error_codes'].forEach(code => {
          const fieldName = code.split("-").slice(1).join("-")
          dispatch(actions.setErrors('contactForm.' + fieldName, {invalid: true}))
        })
      })
      .then(() => {
        dispatch(actions.setPending('contactForm', false))
      })
  }

  render() {
    return (
      <section id={"contact-us"} className="contact-us section-pad">
        <div className="container">
          <Window className="m-auto" maxWidth={"600px"}>
            <WindowCircleButtons key="header-left" />
            <div key="header-center">Contact us</div>
            <div key="content" className="contact-us__window__content">
              <Form model="contactForm"
                    className={"contact-us__form"}
                    onSubmit={values => this.postContactUs(values)}>
                <div className={"contact-us__form__row"}>
                  <label>Name:</label>
                  <Control.text
                    model="contactForm.name"
                    component={ContactTextInput}
                    placeholder="Enter your name"
                    validators={{
                      invalid: (val) => !!val.length && val.length >= 3
                    }} />
                  <Errors
                    model="contactForm.name"
                    wrapper={ContactFormErrors}
                    component={ContactFormError}
                    show={{touched: true}}
                    messages={{
                      invalid: 'Name must contain at least 3 characters'
                    }}
                  />
                </div>

                <div className={"contact-us__form__row"}>
                  <label>Email:</label>
                  <Control.text
                    model="contactForm.email"
                    component={ContactTextInput}
                    placeholder="Enter your email"
                    validators={{
                      invalid: (val) => validateEmail(val)
                    }} />
                  <Errors
                    model="contactForm.email"
                    component={ContactFormError}
                    wrapper={ContactFormErrors}
                    show={{touched: true}}
                    messages={{
                      invalid: "Email is invalid"
                    }}
                  />
                </div>

                <div className={"contact-us__form__row"}>
                  <label>Text:</label>
                  <Control.text
                    model="contactForm.text"
                    component={ContactTextAreaInput}
                    placeholder="Enter your message"
                    validators={{
                      invalid: (val) => !!val.length && val.length >= 20
                    }} />
                  <Errors
                    model="contactForm.text"
                    wrapper={ContactFormErrors}
                    component={ContactFormError}
                    show={{touched: true}}
                    messages={{
                      invalid: 'Message must contain at least 20 characters'
                    }}
                  />
                </div>

                <div className={"contact-us__form__submit_wrapper"}>
                  <Button wrapperClass="contact-us__form__submit"
                          size={Button.sizes.md}
                          color={Button.colors.blue}
                          isButton={true}
                          minWidth={'175px'}>
                    Send
                  </Button>
                </div>
              </Form>
            </div>
          </Window>
        </div>
        <Modal show={this.state.modalShow}
               onClose={() => this.triggerModal(false)}
               size={Modal.sizes.sm}
               title={"Form submitted"}>
          <div className={`modal-custom__text text-center`}>
            <p><h3>Your request was successfully submitted.</h3></p>
            <p>We will contact you as soon as possible.</p>
          </div>
        </Modal>
      </section>
    )
  }
}

export default connect(null)(ContactForm)
