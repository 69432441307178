const serviceIcons = require.context('assets/images/services/services-icons', true)
const serviceIconsPath = (name) => serviceIcons(name, true)

const serviceImages = require.context('assets/images/services/services-images', true)
const serviceImagesPath = (name) => serviceImages(name, true)

export default [
  {
    name: "Business Development",
    icon: serviceIconsPath('./business-dev.png'),
    color: "#00E5EA",
    skills: ["Roadshow", "Management of partnerships", "PR Cases", "Work with celebrities and influencers", "Introductions & Networking"],
    image: serviceImagesPath("./business-dev.jpg")
  },
  {
    name: "Telegram Stickers",
    icon: serviceIconsPath('./tg-stickers.png'),
    color: "#FF5A52",
    skills: ["Mascot style creation", "Telegram Stickers Illustrations", "Telegram Implementation & Tracking"],
    image: serviceImagesPath("./stickers.jpg")
  },
  {
    name: "PPC",
    icon: serviceIconsPath('./ppc.png'),
    color: "#3C31F1",
    skills: ["Google Ads campaigns setup", "Facebook/Instagram Ads campaigns setup", "Marketing automation", "Lead/branding campaigns", "Remarketing, retargeting", "Multiplatform analytics setup", "Analytical reports"],
    image: serviceImagesPath("./ppc.jpg")
  },
  {
    name: "Graphic & UI design",
    icon: serviceIconsPath('./design.png'),
    color: "#F6539D",
    skills: ["Web & Graphic Design", "UI/UX Design", "App design", "Illustrations", "Branding & Logo", "Design systems", "Wireframing & Prototypes"],
    image: serviceImagesPath("./graphic-design.jpg")
  },
  {
    name: "Motion Graphics",
    icon: serviceIconsPath('./motion-graphics.png'),
    color: "#E6C029",
    skills: ["VFX", "3D & 2D", "Motion & Compositing", "Promo", "Presentations"],
    image: serviceImagesPath("./motion-design.jpg")
  },
  {
    name: "Front-end development",
    icon: serviceIconsPath('./frontend-dev.png'),
    color: "#53C22B",
    skills: ["HTML5/CSS3, Responsive Web Design", "JS and CSS animation", "Single Page Applications"],
    image: serviceImagesPath("./frontend-dev.jpg")
  },
  {
    name: "IT",
    icon: serviceIconsPath('./it.png'),
    color: "#A574FF",
    skills: ["AR", "DBs", "Server management", "Backend for web-apps, websites etc.", "Mobile games"],
    image: serviceImagesPath("./it.jpg")
  },
  {
    name: "SMM",
    icon: serviceIconsPath('./smm.png'),
    color: "#0D6DFF",
    skills: ["Social media strategy", "Content creation (texts, images, videos)", "Workflows setup", "Social media accounts management", "Engagement with customers/leads"],
    image: serviceImagesPath("./smm.jpg")
  },
  {
    name: "Email Marketing",
    icon: serviceIconsPath('./email.png'),
    color: "#FF0D55",
    skills: ["Design Templates", "Email conversion funnel creation", "Email automation", "Email strategy and content", "Tracking setup", "Manual email campaigns"],
    image: serviceImagesPath("./email-marketing.jpg")
  },
  {
    name: "Online Support Setup",
    icon: serviceIconsPath('./support.png'),
    color: "#FF7A2F",
    skills: ["Help/Support center setup", "Support team workflows setup", "Ticketing system setup", "Support automation", "Onboarding", "Tutorials"],
    image: serviceImagesPath("./online-support.jpg")
  }
]
