import React from "react"
import Button from "../../Button"

import './Service.component.scss'
import Modal from "../../Modal"

const Service = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [modalCoordinates, setModalCoordinates] = React.useState({x: 0, y: 0})

  const closeModal = () => setModalShow(false)
  const openModal = (e) => {
    setModalShow(true)
    setModalCoordinates({x: e.screenX, y: e.screenY - 150})
  }

  const {title, shortDescription, fullDescription, img} = props.item

  return (
    <>
      <div className="service-wrapper">
        <div className="service">
          <div className="service--all-text-wrapper">
            <div className="service__title">{title}</div>
            <div className="service__text">{shortDescription}</div>
            <div>
              <Button size={Button.sizes.md} color={Button.colors.blue} minWidth={'165px'} onClick={openModal}>
                Learn More
              </Button>
            </div>
          </div>
          <div className="service--image-wrapper">
            <img src={img} alt="" />
          </div>
        </div>
      </div>

      <Modal show={modalShow}
             onClose={closeModal}
             clickCoordinates={modalCoordinates}
             size={Modal.sizes.sm}
             title={title}>
        <div className={`modal-custom__text`}>
          {fullDescription.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        </div>
      </Modal>
    </>
  )
}

export default Service
