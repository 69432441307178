import React from 'react'
import PropTypes from 'prop-types'
import Avatar from "../../Avatar"
import ServicesMessage from "../ServicesMessage"
import phoneIcon from "assets/images/icons/phone-icon.svg"
import dotsIcon from "assets/images/icons/3-dots.svg"
import messageIcon from "assets/images/icons/message-icon.svg"
import sendIcon from "assets/images/icons/send-icon.svg"
import emojiIcon from "assets/images/icons/emoji-icon.svg"
import backIcon from "assets/images/icons/back-icon.svg"
import { actions } from "react-redux-form"
import { connect } from "react-redux"
import './ServicesBody.styles.scss'
import { scrollToElementWithId } from "../../../utils"

const ServicesBody = (props) => {

  const handleSubmit = (event) => {
    event.preventDefault()
    scrollToElementWithId("contact-us")
    props.dispatch(actions.change('contactForm.text', event.target[0].value))
    event.target[0].value = ''
  }

  return (
    <div className={"services__body"}>
      <div className="services__body__top">
        <div className={"services__body__top__info"}>
          <button className={"services__body__top__back-btn"} onClick={props.backClicked}><img src={backIcon} alt="" />
          </button>
          <Avatar icon={props.info.icon} color={props.info.color} className={"services__body__top__info_avatar"} />
          <div>
            <div className="services__body__top__info_title">{props.info.name}</div>
            <div className="services__body__top__info_subtitle">Service</div>
          </div>
        </div>
        <div className={"services__body__top__icons"}>
          <button onClick={() => scrollToElementWithId("contact-us")} className={"services__body__top__icons_phone-icon"}>
            <img src={phoneIcon} alt="" />
          </button>
          <button onClick={() => scrollToElementWithId("hero")} className={"services__body__top__icons_dots-icon"}>
            <img src={dotsIcon} alt="" />
          </button>
        </div>
      </div>
      <div className={"services__body__main"}>
        <div className={"services__message__container"}>
          {props.info.skills.length > 0 &&
          <ServicesMessage avatarColor={props.info.color} avatarIcon={props.info.icon} name={props.info.name}>
            <b>We can do:</b>
            <ul className={"services__list-dash"}>
              {props.info.skills.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </ServicesMessage>
          }

          {props.info.image &&
          <ServicesMessage avatarColor={props.info.color} avatarIcon={props.info.icon} name={props.info.name}>
            <img src={props.info.image} alt="" className={"services__body__image"} />
          </ServicesMessage>
          }
        </div>
      </div>
      <div className="services__body__bottom">
        <form className={"services__body__bottom__form"} onSubmit={handleSubmit}>
          <label htmlFor={"input-message"}><img src={messageIcon} alt="" /></label>
          <input type="text" className={"services__body__bottom__input"} placeholder="Write a message..." id={"input-message"} />
          <div className={"services__body__bottom__emoji_btn"}><img src={emojiIcon} alt="" /></div>
          <button type="submit" className={"services__body__bottom__submit"}><img src={sendIcon} alt="" /></button>
        </form>
      </div>
    </div>
  )
}

ServicesBody.propTypes = {
  backClicked: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired
}

export default connect(null)(ServicesBody)
