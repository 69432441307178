import React from 'react'
import './ServicesSidebar.styles.scss'
import PropTypes from "prop-types"
import ServicesSidebarItem from '../ServicesSidebarItem'
import Avatar from "../../Avatar"
import servicesIcon from '../../../assets/images/services/services-icons/services.png'

const ServicesSidebar = (props) => {
  return (
    <div className="services__sidebar">
      <div className={"services__sidebar__top"}>
        <Avatar icon={servicesIcon} color={"#E5E5E5"} className={"services__sidebar__top_avatar"} />
        <span>Services</span>
      </div>
      {props.tabs.map((tab, i) =>
        <ServicesSidebarItem
          key={i}
          title={tab.name}
          color={tab.color}
          icon={tab.icon}
          onClick={() => props.onTabSelected(i)}
          subtitle={"We can do " + tab.skills.reduce((x1, x2) => x1 + ', '+  x2)}
          isActive={i === props.selectedTabIndex} />)}
    </div>
  )
}

ServicesSidebar.propTypes = {
  tabs: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  onTabSelected: PropTypes.func
}

export default ServicesSidebar

