import React from 'react'
import './AgencyExpertiseSidebar.styles.scss'
import PropTypes from "prop-types"

const AgencyExpertiseSidebar = (props) => {
  return (
    <div className="agency-expertise__sidebar">
      <div className={"agency-expertise__sidebar__title"}>Expertise</div>
      {props.tabs.map((tab, i) =>
        <button
          key={i}
          onClick={() => {
            props.onTabSelected(i)
          }}
          className={"agency-expertise__sidebar__tab"
          + (i === props.selectedTabIndex ? " agency-expertise__sidebar__tab--active" : "")}>
          <img src={tab.nameEmoji} alt="" />
          {tab.name}
        </button>)}
    </div>
  )
}

AgencyExpertiseSidebar.propTypes = {
  tabs: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  onTabSelected: PropTypes.func
}

export default AgencyExpertiseSidebar
