import React from 'react'
import Window from "../Window"
import WindowCircleButtons from "../Window/WindowCircleButtons"
import Button from "../Button"
import CustomProgressBar from "../ProgressBar/ProgressBar"
import data from './OnBoardingProcess.data'

import './OnboardingProcess.scss'
import { scrollToElementWithId } from "../../utils"

const OnboardingProcess = () => {
  const [progress, setProgress] = React.useState(0)
  const maxProgress = data.length

  const increaseProgress = () => {
    if (progress < maxProgress)
      setProgress(progress + 1)
  }
  return (
    <section className="onboarding-process section-pad">
      <div className="container">
        <Window>
          <WindowCircleButtons key="header-left" />
          <div key="header-center">Onboarding Process</div>
          <div key="content" className="onboarding-process__content">
            <div>
              {progress > 0 &&
              <>
                <Window className={`onboarding-process__window-step onboarding-process__window-step--step${progress}`}>
                  <div key="content" className="window__content--with-pad onboarding-process__window-step__content">
                    <div className={"onboarding-process__window-step__emoji_wrapper"}>
                      <img src={data[progress - 1].img} alt="" className="onboarding-process__window-step__emoji" />
                    </div>
                    <div className={"onboarding-process__window-step__text"}>
                      <div>{data[progress - 1].text}</div>
                      <div className={"onboarding-process__window-step__btn-wrapper"}>
                        {progress < data.length ?
                          <Button size={Button.sizes.xs}
                                  color={Button.colors.violet}
                                  minWidth={'70px'}
                                  onClick={increaseProgress}>
                            Next
                          </Button> :
                          <Button size={Button.sizes.xs}
                                  color={Button.colors.violet}
                                  minWidth={'70px'}
                                  onClick={() => {
                                    scrollToElementWithId("contact-us")
                                    setProgress(0)
                                  }}>
                            Start work
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                </Window>
              </>
              }
            </div>

            <div>
              <CustomProgressBar percentage={progress / maxProgress} />
              {progress === 0 &&
              <div className={"onboarding-process__button-start-outer"}>
                <Button size={Button.sizes.md} color={Button.colors.blue} minWidth={'240px'} onClick={increaseProgress}>Start our work</Button>
              </div>
              }
            </div>
          </div>
        </Window>
      </div>
    </section>
  )
}

export default OnboardingProcess
