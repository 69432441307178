const emojiIcons = require.context('assets/images/emojis', true)
const emojiIconsPath = (name) => emojiIcons(name, true)

export default [
  {
    img: emojiIconsPath("./settings--big.png"),
    text: "We set up a call with our Operational Manager and Tech Lead to define and understand your needs, goals and business operations."
  },
  {
    img: emojiIconsPath("./pin--big.png"),
    text: "We create a project roadmap and get back to you with an offer and KPIs."
  },
  {
    img: emojiIconsPath("./letter--big.png"),
    text: "We agree on the final deliverables and sign the Service Agreement (SA)."
  },
  {
    img: emojiIconsPath("./man--big.png"),
    text: "We assign a Project Manager and Project Lead to you, together with the team of marketers and developers needed for your project."
  },
  {
    img: emojiIconsPath("./rocket--big.png"),
    text: "We start our work"
  }
]
