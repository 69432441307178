import React from 'react'
import PropTypes from 'prop-types'
import './Window.styles.scss'
import { getComponent } from "../../utils"

class Window extends React.Component {
  render() {
    return (
      <div className={`window ${this.props.className}`} style={{maxWidth: this.props.maxWidth}}>
        <div className="window__header">
          <div className="window__header_left">
            {getComponent(this.props.children, 'header-left')}
          </div>
          <div className="window__header_center">
            {getComponent(this.props.children, 'header-center')}
          </div>
          <div className="window__header_right">
            {getComponent(this.props.children, 'header-right')}
          </div>
        </div>
        <div className="window__content">
          {getComponent(this.props.children, 'content')}
        </div>
      </div>
    )
  }
}

Window.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.string
}

Window.defaultProps = {
  className: '',
}

export default Window
