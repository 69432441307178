const imagesDigital = require.context('assets/images/expertises/digital', true)
const imagePathDigital = (name) => imagesDigital(name, true)

const imagesIT = require.context('assets/images/expertises/it', true)
const imagePathIT = (name) => imagesIT(name, true)

export default [
  {
    name: "Digital Marketing",
    nameEmoji: require('../../assets/images/emojis/red-circle.png'),
    items: [
      {
        img: imagePathDigital('./photoshop-cc.svg'),
        title: "Photoshop",
      },
      {
        img: imagePathDigital('./adobe-illustrator-cc.svg'),
        title: "Illustrator",
        description: "Adobe Illustrator is Industry-standard vector graphics creation software and is used for creating logos,web graphics, cartoons, character designs, infographics, website layout, mobile UI/UX design, banners, T-shirt designs, abstract art, wallpapers etc."
      },
      {
        img: imagePathDigital('./indesign-cc.svg'),
        title: "InDesign",
      },
      {
        img: imagePathDigital('./adobe-xd.svg'),
        title: "XD",
      },
      {
        img: imagePathDigital('./sketch.svg'),
        title: "Sketch",
      },
      {
        img: imagePathDigital('./axure.png'),
        title: "Axure RP",
      },
      {
        img: imagePathDigital('./invision.svg'),
        title: "Invision App",
      },
      {
        img: imagePathDigital('./figma.svg'),
        title: "Figma",
      },
      {
        img: imagePathDigital('./zeplin.svg'),
        title: "Zeplin",
      },
      {
        img: imagePathDigital('./proto-io.svg'),
        title: "Proto.io",
      },
      {
        img: imagePathDigital('./cinema4d.png'),
        title: "Cinema 4D",
      },
      {
        img: imagePathDigital('./3dmax.svg'),
        title: "3D Max",
      },
      {
        img: imagePathDigital('./after-effects-cc.svg'),
        title: "Adobe After Effects",
      },
      {
        img: imagePathDigital('./customer-io.svg'),
        title: "Customer.io",
      },
      {
        img: imagePathDigital('./mailchimp.svg'),
        title: "Mailchimp",
      },
      {
        img: imagePathDigital('./segment-io.svg'),
        title: "Segment.io",
      },
      {
        img: imagePathDigital('./sendpulse.png'),
        title: "Sendpulse",
      },
      {
        img: imagePathDigital('./zendesk.svg'),
        title: "Zendesk",
      },
      {
        img: imagePathDigital('./intercom.svg'),
        title: "Intercom",
      },
      {
        img: imagePathDigital('./drift.svg'),
        title: "Drift",
      },
      {
        img: imagePathDigital('./kayako.svg'),
        title: "Kayako",
      },
      {
        img: imagePathDigital('./crisp.png'),
        title: "Crisp",
      },
      {
        img: imagePathDigital('./google-ads.png'),
        title: "Google Ads",
      },
      {
        img: imagePathDigital('./google-analytics.png'),
        title: "Google Analytics",
      },
      {
        img: imagePathDigital('./google-data-studio.svg'),
        title: "Google Data Studio",
      }
    ]
  },
  {
    name: "IT",
    nameEmoji: require('../../assets/images/emojis/blue-circle.png'),
    items: [
      {img: imagePathIT('./bitbucket.png')},
      {img: imagePathIT('./aws.png')},
      {img: imagePathIT('./do.png')},
      {img: imagePathIT('./csharp.png')},
      {img: imagePathIT('./azure.png')},
      {img: imagePathIT('./docker.png')},
      {img: imagePathIT('./redux.png')},
      {img: imagePathIT('./swarm.png')},
      {img: imagePathIT('./react.png')},
      {img: imagePathIT('./html-js-css.png')},
      {img: imagePathIT('./trello.png')},
      {img: imagePathIT('./spring.png')},
      {img: imagePathIT('./jenkins.png')},
      {img: imagePathIT('./gitlab.png')},
      {img: imagePathIT('./gitlab-ci.png')},
      {img: imagePathIT('./git.png')},
      {img: imagePathIT('./mongodb.png')},
      {img: imagePathIT('./ms-sql.png')},
      {img: imagePathIT('./mysql.png')},
      {img: imagePathIT('./java.png')},
      {img: imagePathIT('./unity.png')},
      {img: imagePathIT('./github.png')},
      {img: imagePathIT('./poeditor.png')},
      {img: imagePathIT('./segment.png')}
    ]
  }
]
