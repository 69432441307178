import React from 'react'
import 'styles/main.scss'
import Nav from "../Navigation"
import Footer from "../Footer"
import routes from "routes"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Provider } from 'react-redux'
import store from 'store.js'

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

class App extends React.Component {
  state = {
    loading: true
  }

  componentDidMount() {
    demoAsyncCall().then(() => this.setState({loading: false}))
  }

  render() {
    const {loading} = this.state
    if (loading && process.env.NODE_ENV !== 'development')
      return null

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Nav />
          <div className="content">
            <Switch>
              {
                Object
                  .entries(routes)
                  .map(([key, value]) => {
                    return <Route
                      exact
                      path={key}
                      key={key}
                      component={value}
                    />
                  })
              }
            </Switch>
          </div>
          <Footer />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
