import React from 'react'
import Slider from "react-slick"
import Window from "../Window"
import WindowCircleButtons from "../Window/WindowCircleButtons"
import Service from "./Service-component/Service.component"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './CommunicationManagement.scss'
import data from './CommunicationManagement.data'

class CommunicationManagement extends React.Component {
  render() {
    return (
      <section id="communications" className="communication-management section-pad">
        <div className="container">
          <Window className="m-auto" maxWidth={"1080px"}>
            <WindowCircleButtons key="header-left"/>
            <div key="header-center">Communication Management</div>
            <div key="content" className="communication-management__window__content">
              <div className="communication-management__title">We develop strategies for businesses and politicians. We
                manage industry leading PR-Campaigns and create PR-Cases for your project. We are fully managing the
                production, strategy and project management.
              </div>
              <div className="communication-management__title">We bring effective communication management into your
                project pipeline!
              </div>
              <Slider {...data.sliderSettings}>
                {
                  data.items.map((item, i) => {
                    return <div key={i} className={"communication-management__slider-item"}>
                      <Service item={item} />
                    </div>
                  })
                }
              </Slider>
            </div>
          </Window>
        </div>
      </section>
    )
  }
}

export default CommunicationManagement
