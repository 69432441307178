import React from 'react'
import './Modal.styles.scss'
import PropTypes from "prop-types"
import Window from "../Window"
import WindowCircleButtons from "../Window/WindowCircleButtons"
import { Portal } from 'react-portal'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import classNames from "classnames"

const Modal = ({show, modalClassName, size, onClose, clickCoordinates, title, children}) => {
  const modalClose = () => {
    document.body.classList.remove("noscroll-modal")
    onClose()
  }

  show && document.body.classList.add("noscroll-modal")

  return <ReactCSSTransitionGroup
    transitionName="modal-custom-animation"
    transitionEnterTimeout={350}
    transitionLeaveTimeout={300}
  >
    {show ?
      (<Portal>
        <div className={"modal-custom__wrapper"}>
          <div className={classNames("modal-custom", modalClassName, size, !clickCoordinates && "modal-custom--centered")}
               style={clickCoordinates ? {transform: `translate(${clickCoordinates.x}px, ${clickCoordinates.y}px)`} : null}>
            <Window className={"modal-custom__window"}>
              <WindowCircleButtons key="header-left" onClose={modalClose} />
              <div key={"header-center"}>{title}</div>
              <React.Fragment key="content">{children}</React.Fragment>
            </Window>
          </div>
          <div className={"modal-custom__bg"} onClick={modalClose} />
        </div>
      </Portal>)
      : null
    }
  </ReactCSSTransitionGroup>
}

Modal.sizes = {
  w100: "",
  sm: "modal-custom--sm",
  md: "modal-custom--md",
  lg: "modal-custom--lg"
}

Modal.propTypes = {
  show: PropTypes.bool,
  modalClassName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Modal.sizes)),
  onClose: PropTypes.func,
  clickCoordinates: PropTypes.object,
  title: PropTypes.string
}

Modal.defaultProps = {
  show: false,
  modalClassName: "",
  size: Modal.sizes.w100,
  title: ""
}


export default Modal
