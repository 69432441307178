import React from 'react'
import './ServicesSidebarTab.styles.scss'
import PropTypes from "prop-types"
import Avatar from "../../Avatar"

const ServicesSidebarTab = (props) => {
  return (
    <div className={`services__sidebar__tab ${props.isActive ? " services__sidebar__tab--active" : ""}`} style={{borderColor: props.color}} onClick={props.onClick}>
      <Avatar icon={props.icon} color={props.color} className={"services__sidebar__tab_avatar"} />
      <div className={"services__sidebar__tab_body"}>
        <div className={"services__sidebar__tab_body_top"}>
          <div className={"services__sidebar__tab_title"}>{props.title}</div>
          <div className={"services__sidebar__tab_time"}>{props.time}</div>
        </div>
        <div className={"services__sidebar__tab_subtitle"}>{props.subtitle}</div>
      </div>
    </div>
  )
}

ServicesSidebarTab.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  time: PropTypes.string
}

ServicesSidebarTab.defaultProps = {
  isActive: false,
  time: "11:44 PM"
}

export default ServicesSidebarTab
