export default {
  footerItems: [
    {
      name: "Services",
      page: "/",
      anchor: "services"
    },
    {
      name: "Communications",
      page: "/",
      anchor: "communications"
    },
    {
      name: "About us",
      page: "/",
      anchor: "about-us"
    }
  ]
}
