import React from 'react'
import './NavigationSidebar.styles.scss'
import { withRouter } from "react-router-dom"
import Button from "../../Button"
import navigationData from '../Navigation.data'
import logo from "../../../assets/images/common/logo-white.svg"
import close_icon from "../../../assets/images/common/close-icon.svg"
import { HashLink as Link } from "react-router-hash-link"
import commonData from "../../../config/commonData"
import { scrollToElementWithId } from "../../../utils"

const NavigationSidebar = props => {
  return (
    <>
      <div className="navigation__side__darkpanel" onClick={props.toggle} />
      <nav className="navigation__side">
        <div className="navigation__side_top">
          <Link to="/" onClick={props.toggle} className="navigation__side_top__logo">
            <img src={logo} alt={commonData.siteName} />
          </Link>
          <button onClick={props.toggle} className="navigation__side_top__close">
            <img src={close_icon} alt="" />
          </button>
        </div>
        <div className="navigation__side__items">
          {
            navigationData.navigationItems.map((item, i) => {
              return <Button
                key={i}
                wrapperClass={"navigation__side__item"}
                to={item.page}
                onClick={() => {
                  props.toggle()
                  scrollToElementWithId(item.anchor)
                }}>
                {item.name}
              </Button>
            })
          }
        </div>
      </nav>
    </>
  )
}

export default withRouter(NavigationSidebar)
