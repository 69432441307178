import React from 'react'
import './Avatar.styles.scss'
import PropTypes from "prop-types"

const Avatar = (props) => {
  return (
    <div className={`avatar ${props.className}`} style={{backgroundColor: props.color}}>
      <img src={props.icon} alt="" />
    </div>
  )
}

Avatar.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
}

export default Avatar

