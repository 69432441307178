import React from 'react'
import Hero from "../../components/Hero"
import CommunicationManagement from "../../components/CommunicationManagement/CommunicationManagement"
import Services from "../../components/Services"
import AboutUs from "../../components/About-us/AboutUs"
import ContactForm from "../../components/ContactForm"
import AgencyExpertise from "../../components/AgencyExpertise"
import OnboardingProcess from "../../components/OnboardingProcess"
// import Posts from "../../components/Posts"


export default function IndexPage() {
  return (
    <React.Fragment>
      <Hero />
      <CommunicationManagement />
      <Services />
      <AgencyExpertise />
      <OnboardingProcess />
      {/*<Posts />*/}
      <AboutUs />
      <ContactForm />
    </React.Fragment>
  )
}
