import React from "react"
import Window from "../Window"
import WindowCircleButtons from "../Window/WindowCircleButtons"

import './AboutUs.scss'
import logoFull from '../../assets/images/common/logo-full.svg'

export default function () {
  return (
    <>
      <section id={"about-us"} className="about-us section-pad">
        <div className="container">
          <Window className="m-auto" maxWidth={"810px"}>
            <WindowCircleButtons key="header-left" />
            <div key="header-center">About us</div>
            <div key="content" className="about-us__window__window__content">
              <div className="about-us__window__content-inner">
                <div className="about-us__window__content-inner__image">
                  <img src={logoFull} alt="" />
                </div>
                <div className="about-us__window__content-inner__text">Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.</div>
                <div className="about-us__window__content-inner__text">Ideas are overrated. We bring the ideas to life and deliver fascinating experiences. No matter how ambitious and brave they are — we scale fast and produce things that will amaze you.</div>
                <div className="about-us__window__content-inner__text">Ideas are overrated. We bring the ideas to life and deliver fascinating experiences. No matter how ambitious and brave they are — we scale fast and produce things that will amaze you.</div>
              </div>
            </div>
          </Window>
        </div>
      </section>
    </>
  )
}
