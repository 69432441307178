import { animateScroll as scroll } from "react-scroll/modules"

/**
 *  NB: to use this method, you need to bind it to the object instance calling it
 */
export function bindMethodsToSelf(objClass, otherMethodsToIgnore = []) {
  const self = this
  Object.getOwnPropertyNames(objClass.prototype)
    .forEach(method => {
      //skip constructor, render and any overrides
      //of lifecycle methods
      if (method.startsWith('component')
        || method === 'constructor'
        || method === 'render') return
      //any other methods you don't want bound to self
      if (otherMethodsToIgnore.indexOf(method) > -1) return
      //bind all other methods to class instance
      self[method] = self[method].bind(self)
    })
}

export function getComponent(children, key) {
  if (children) {
    if (children.length > 1)
      return children.filter((comp) => {
        return comp.key === key
      })

    if (children.key === key)
      return children
  }
}

export function elementPositionById(id) {
  const rect = document.getElementById(id).getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

export function scrollToElementWithId(id, options = {duration: 750}) {
  scroll.scrollTo(elementPositionById(id).top - 40, options)
}
