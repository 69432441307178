import React from 'react'
import WindowCircleButtons from "../Window/WindowCircleButtons"
import data from "./AgencyExpertise.data"
import Window from "../Window"
import AgencyExpertiseSidebar from "./AgencyExpertiseSidebar"
import AgencyExpertiseItem from "./AgencyExpertiseItem"
import './AgencyExpertise.styles.scss'

const AgencyExpertise = () => {
  const [tabIndex, selectTab] = React.useState(0)

  return (
    <section id="expertise" className="agency-expertise__section section-pad">
      <div className="container">
        <Window className="agency-expertise">
          <WindowCircleButtons key="header-left" />
          <div key="header-center">{data[tabIndex].name}</div>
          <div key="content" className="agency-expertise__content">
            <AgencyExpertiseSidebar tabs={data} selectedTabIndex={tabIndex} onTabSelected={selectTab} />
            <div className={`agency-expertise__items`}>
              {data[tabIndex].items.map((item, i) =>
                <AgencyExpertiseItem key={data[tabIndex].name + ":" + i}
                                     title={item.title}
                                     description={item.description}
                                     img={item.img}
                                     whiteBg={tabIndex === 1} />
              )}
            </div>
          </div>
        </Window>
      </div>
    </section>
  )
}

export default AgencyExpertise
