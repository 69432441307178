import React from 'react'
import './ServicesMessage.styles.scss'
import PropTypes from "prop-types"
import Avatar from "../../Avatar"
import readIcon from "../../../assets/images/common/read-icon-blue.svg"

const ServicesMessage = (props) => {
  return (
    <div className={"services__message"}>
      <div className="services__message__avatar_column">
        <Avatar icon={props.avatarIcon} color={props.avatarColor} className={"services__message__avatar"} />
      </div>
      <div className={"services__message__body_column"}>
        <div className={"services__message__info"}>
          <div className="services__message__info_name">{props.name}</div>
          <div className="services__message__info_time">
            <img src={readIcon} alt="" />
            <span>{props.time}</span>
          </div>
        </div>
        <div className={"services__message__body"}>{props.children}</div>
      </div>
    </div>
  )
}

ServicesMessage.propTypes = {
  avatarIcon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  avatarColor: PropTypes.string,
  time: PropTypes.string
}

ServicesMessage.defaultProps = {
  time: "11:44 PM"
}

export default ServicesMessage
