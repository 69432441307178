import React from 'react'
import PropTypes from 'prop-types'
import './Button.styles.scss'
import { HashLink as Link } from 'react-router-hash-link'

const Button = props => {
  let className = `button ${props.size} ${props.color}`
  let btnElem

  if (props.to)
    btnElem = <Link className={className} to={props.to} onClick={props.onClick}>{props.children}</Link>
  else if (props.onClick || props.isButton)
    btnElem = <button className={className} onClick={props.onClick}>{props.children}</button>
  else
    btnElem = <a className={className} href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>

  return <div className={`button__wrapper ${props.wrapperClass}`} style={{minWidth: props.minWidth}}>{btnElem}</div>
}

const sizes = {
  xs: "button--xs",
  sm: "button--sm",
  md: "button--md"
}

const colors = {
  grey: "button--grey",
  blue: "button--blue",
  violet: "button--violet"
}

Button.sizes = sizes
Button.colors = colors

Button.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  anchor: PropTypes.string,
  wrapperClass: PropTypes.string,
  minWidth: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  color: PropTypes.oneOf(Object.values(colors)),
  isButton: PropTypes.bool
}

Button.defaultProps = {
  size: sizes.sm,
  color: colors.grey
}

export default Button
