import picture1 from 'assets/images/communications/picture1.svg'
import picture2 from 'assets/images/communications/picture2.svg'
import picture3 from 'assets/images/communications/picture3.svg'
import picture4 from 'assets/images/communications/picture4.svg'

export default {
  sliderSettings: {
    infinite: false,
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick'
      }
    ]
  },
  items: [
    {
      title: "Strategy",
      shortDescription: "For the sake of effectiveness of your communication strategy, we create complex, multilevel solutions, handpick best tactics and research the specifics of your audiences. Our main goal is to deliver the most effective communications to your project and will overcome any obstacles to make this DONE.",
      fullDescription: [
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.",
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.",
      ],
      img: picture1
    },
    {
      title: "Reputational Management",
      shortDescription: "For the sake of effectiveness of your communication strategy, we create complex, multilevel solutions, handpick best tactics and research the specifics of your audiences. Our main goal is to deliver the most effective communications to your project and will overcome any obstacles to make this DONE.",
      fullDescription: [
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.",
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team."
      ],
      img: picture2
    },
    {
      title: "Public Image",
      shortDescription: "For the sake of effectiveness of your communication strategy, we create complex, multilevel solutions, handpick best tactics and research the specifics of your audiences. Our main goal is to deliver the most effective communications to your project and will overcome any obstacles to make this DONE.",
      fullDescription: [
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.",
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team."
      ],
      img: picture3
    },
    {
      title: "Crisis Management",
      shortDescription: "For the sake of effectiveness of your communication strategy, we create complex, multilevel solutions, handpick best tactics and research the specifics of your audiences. Our main goal is to deliver the most effective communications to your project and will overcome any obstacles to make this DONE.",
      fullDescription: [
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team.",
        "Our core mission is to help businesses become more successful in what they do by providing world-class software developing, design, marketing and consulting services. We operate on the principles of partnership, respect, and understanding. Our core value proposition is 24/7 support and don’t take lunch breaks, so you can have 100% daily access to our team."
      ],
      img: picture4
    }
  ]
}
