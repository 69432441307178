import React from 'react'
import './Footer.styles.scss'
import logo from "../../assets/images/common/logo-white.svg"
import { HashLink as Link } from "react-router-hash-link"
import commonData from 'config/commonData'
import Button from "../Button"
import footerData from './Footer.data'
import { scrollToElementWithId } from "../../utils"

export default function () {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__copyright">
          <Link to="/" className="footer__copyright__logo">
            <img src={logo} alt={commonData.siteName} />
          </Link>
          <div className="footer__copyright__text">Copyright © 2021 {commonData.siteName}</div>
        </div>
        <div className="footer__nav-buttons">
          {
            footerData.footerItems.map((item, i) => {
              return <Button
                key={i}
                wrapperClass={"footer__nav-button"}
                to={item.page}
                onClick={() => {
                  scrollToElementWithId(item.anchor)
                }}>
                {item.name}
              </Button>
            })
          }
        </div>
        <div className="footer__social-buttons">
          {
            commonData.socials.map((item, i) => {
              return <Button
                key={i}
                wrapperClass={"footer__social-button"}
                href={item.link}>
                <img src={item.img} alt={item.name} />
              </Button>
            })
          }
        </div>
      </div>
    </footer>
  )
}
