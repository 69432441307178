import React from 'react'
import './WindowCircleButtons.styles.scss'
import PropTypes from 'prop-types'

class WindowCircleButtons extends React.Component {
  render() {
    if (this.props.onClose)
      return (
        <div className="window__circle-buttons">
          <button className="window__circle-button window__circle-button--red" onClick={this.props.onClose} />
          <div className="window__circle-button" />
          <div className="window__circle-button" />
        </div>
      )
    return (
      <div className="window__circle-buttons">
        <div className="window__circle-button" />
        <div className="window__circle-button" />
        <div className="window__circle-button" />
      </div>
    )
  }
}

WindowCircleButtons.propTypes = {
  onClose: PropTypes.func
}

export default WindowCircleButtons
