import React from "react"
import PropTypes from "prop-types"

import './ProgressBar.scss'


const ProgressBar = (props) => {
  return (
    <div className="progress-bar-custom">
      <Filler percentage={props.percentage} />
    </div>
  )
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired
}

const Filler = (props) => {
  return (
    <div className="filler" style={{width: `${props.percentage * 100}%`}} />
  )
}

export default ProgressBar