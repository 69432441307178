import { createStore } from 'redux'
import { combineForms } from 'react-redux-form'

// If you want your entire store to have the form state...
const store = createStore(combineForms({
  contactForm: {
    name: '',
    email: '',
    text: ''
  }
}))

export default store
