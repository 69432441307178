import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { withRouter } from "react-router-dom"
import logo from "assets/images/common/logo-white.svg"
import menu_icon from "assets/images/common/menu-icon.svg"
import NavigationSidebar from "./NavigationSidebar"
import navigationData from './Navigation.data'
import commonData from "../../config/commonData"
import Button from "../Button"
import './Navigation.styles.scss'
import { scrollToElementWithId } from "../../utils"

class Navigation extends React.Component {
  state = {
    opened: false
  }

  toggleOpened = () => {
    this.setState((prev) => {
      return {opened: !prev.opened}
    })
    document.body.classList.toggle("overflow-hidden-navbar", !this.state.opened)
  }

  render() {
    return (
      <header className={`navigation${this.state.opened ? " navigation--opened" : ""}`}>
        <nav className="navigation__top">
          <div className="container navigation__top__container">
            <Link to="/" className="navigation__top__logo">
              <img src={logo} alt={commonData.siteName} />
            </Link>
            <div className="navigation__top__items">
              {
                navigationData.navigationItems.map((item, i) => {
                  return <Button
                    key={i}
                    wrapperClass={"navigation__top__item"}
                    to={item.page}
                    onClick={() => {
                      scrollToElementWithId(item.anchor)
                    }}>
                    {item.name}
                  </Button>
                })
              }
            </div>
            <Button wrapperClass="navigation__top__toggle" onClick={this.toggleOpened}>
              <img src={menu_icon} alt="" />
            </Button>
          </div>
        </nav>

        <NavigationSidebar toggle={this.toggleOpened} />
      </header>
    )
  }
}

export default withRouter(Navigation)
