import React from 'react'
import Modal from "../../Modal"
import PropTypes from "prop-types"
import './AgencyExpertiseItem.styles.scss'

const AgencyExpertiseItem = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [modalCoordinates, setModalCoordinates] = React.useState({x: 0, y: 0})

  const closeModal = () => setModalShow(false)
  const openModal = (e) => {
    setModalShow(true)
    setModalCoordinates({x: e.screenX, y: e.screenY - 150})
  }

  let className = "agency-expertise__item"
  if (props.whiteBg) className += " agency-expertise__item--white-bg"
  if (props.description) className += " clickable"

  return <>
    <div className={className} onClick={openModal}>
      <img className="agency-expertise__item_img" src={props.img} alt="" />
      {props.title && <div className={"agency-expertise__item_title"}>{props.title}</div>}
    </div>
    {props.description &&
    <Modal show={modalShow}
           onClose={closeModal}
           clickCoordinates={modalCoordinates}
           size={Modal.sizes.sm}
           title={props.title}>
      <div className={`modal-custom__text`}>{props.description}</div>
    </Modal>
    }
  </>
}

AgencyExpertiseItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  whiteBg: PropTypes.bool
}

export default AgencyExpertiseItem

