// import facebookImg from './../assets/images/icons/facebook.svg'
// import twitterImg from './../assets/images/icons/twitter.svg'
import telegramImg from './../assets/images/icons/telegram.svg'

export default {
  siteName: "Laurus Agency",
  socials: [
    // {
    //   name: "Facebook",
    //   link: "https://www.facebook.com/",
    //   img: facebookImg
    // },
    // {
    //   name: "Twitter",
    //   link: "https://twitter.com/",
    //   img: twitterImg
    // },
    {
      name: "Telegram",
      link: "https://t.me/hmababun",
      img: telegramImg
    }
  ]
}
