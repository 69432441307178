export default {
  navigationItems: [
    {
      name: "🛠 Services",
      page: "/",
      anchor: "services"
    },
    {
      name: "💬 Communications",
      page: "/",
      anchor: "communications"
    },
    {
      name: "🔍 Expertise",
      page: "/",
      anchor: "expertise"
    },
    // {
    //   name: "✏️ Blog",
    // page: "/",
    //   anchor: "blog"
    // },
    {
      name: "😉 About us",
      page: "/",
      anchor: "about-us"
    },
    {
      name: "✉️ Contact us",
      page: "/",
      anchor: "contact-us"
    }
  ]
}
