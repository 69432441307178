import smileEmoji from 'assets/images/emojis/smile--big.png'
import monkeyEmoji from 'assets/images/emojis/monkey--big.png'
import hourGlassEmoji from 'assets/images/emojis/hourglass--big.png'
import thumbUpEmoji from 'assets/images/emojis/thumbup--big.png'
import lightBulbEmoji from 'assets/images/emojis/lightbulb--big.png'

export default {
  whyUs: [
    {
      img: smileEmoji,
      text: "Client’s happiness as the main KPI"
    },
    {
      img: monkeyEmoji,
      text: "Deep integration of our team and efforts into your project"
    },
    {
      img: hourGlassEmoji,
      text: "Effectivity that is higher than average"
    },
    {
      img: thumbUpEmoji,
      text: "Responsibility for all stages of work"
    },
    {
      img: lightBulbEmoji,
      text: "Ability to scale our resources to produce more for you"
    }
  ]
}
