//modules
import React from 'react'
// components
import Button from "../Button"
import Window from '../Window'
import WindowCircleButtons from '../Window/WindowCircleButtons'
//images
import videoPreview from 'assets/images/hero/hero-bg.png'
import puzzleEmoji from 'assets/images/emojis/puzzle--big.png'
//styles
import './Hero.styles.scss'
//data
import data from './Hero.data'
import { scrollToElementWithId } from "../../utils"

export default function Hero(props) {
  return (
    <>
      <section className="hero section-pad" id={"hero"}>
        <div className="container">
          <div className="hero__grid">
            <div className="hero__grid__column-left">
              <Window className="hero__window-2">
                <div key="content" className="window__content--with-pad hero__window-2__content">
                  <div className={"hero__window-2__top-wrapper"}>
                    <img className="hero__window-2__top-wrapper_icon" src={puzzleEmoji} alt="" />
                    <div className="hero__window-2__top-wrapper_text">
                      Blurring the lines between IT development, digital marketing, and communication management
                    </div>
                  </div>
                  <Button
                    wrapperClass={"hero__window-2__button"}
                    size={Button.sizes.md}
                    color={Button.colors.blue}
                    minWidth={"190px"}
                    onClick={() => scrollToElementWithId("contact-us")}
                  >
                    Get in touch
                  </Button>
                </div>
              </Window>

              <Window className="hero__window-video">
                <WindowCircleButtons key="header-left" />
                <div key="header-center">Laurus_Agency</div>
                <div key="content">
                  <div className="hero__window-video__video_outer">
                    <img className="hero__window-video__video" src={videoPreview} alt="" />
                    <Button
                      wrapperClass={"hero__window-video__button"}
                      size={Button.sizes.md}
                      color={Button.colors.violet}
                      href={"/laurus_presentation.pdf"}
                    >
                      Download our Portfolio Presentation
                    </Button>
                  </div>
                </div>
              </Window>
            </div>
            <div className="hero__grid__column-right">
              <Window className="hero__window-whyus">
                <WindowCircleButtons key="header-left" />
                <div key="content" className="window__content--with-pad">
                  <div className="hero__window-whyus__title">Why us?</div>
                  <div className="hero__window-whyus__items">
                    {
                      data.whyUs.map((item, i) => {
                        return <div key={i} className="hero__window-whyus__item">
                          <img className="hero__window-whyus__item_img" src={item.img} alt="" />
                          <div className="hero__window-whyus__item_text">{item.text}</div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </Window>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
