import React from 'react'
import WindowCircleButtons from "../Window/WindowCircleButtons"
import Window from "../Window"
import ServicesSidebar from "./ServicesSidebar"
import ServicesBody from "./ServicesBody"
import data from './Services.data.js'
import './Services.styles.scss'
import { scrollToElementWithId } from "utils"

const Services = () => {
  const [tabIndex, setTab] = React.useState(0)
  const [showContent, setShowContent] = React.useState(false)

  const selectTab = (tab) => {
    setTab(tab)
    setShowContent(true)
    scrollToElementWithId("services", {duration: 500})
  }

  return (
    <section id="services" className="services__section section-pad">
      <div className="container">
        <Window className="services">
          <WindowCircleButtons key="header-left" />
          <div key="content" className={"services__content" + (showContent ? " services__content--opened" : "")}>
            <ServicesSidebar tabs={data} selectedTabIndex={tabIndex} onTabSelected={selectTab} />
            <ServicesBody info={data[tabIndex]} backClicked={() => setShowContent(false)} />
          </div>
        </Window>
      </div>
    </section>
  )
}

export default Services

